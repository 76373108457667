import {
	post
} from '@/http'
// 根据分类获取筛选条件
export function getConditionList(data) {
	return new Promise((resolve, reject) => {
		post('/Condition/GetConditionList', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
// 商品列表
export function getProductList(data) {
	return new Promise((resolve, reject) => {
		post('/Product/GetProductList', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}


// 活动商品列表
export function GetPromotionList(MID) {
	return new Promise((resolve, reject) => {
		post('/Product/GetPromotionList', {
				MID
			})
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 回逆商品价格筛选条件
export function getPriceCondition(data) {
	return new Promise((resolve, reject) => {
		post('/Product/GetPriceCondition', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 回逆商品价格筛选条件
export function getProductType(data) {
	return new Promise((resolve, reject) => {
		post('/Product/GetProductType', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
