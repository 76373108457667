import {
	post
} from '@/http'

// 商品详情
export function getProductDetail(data) {
	return new Promise((resolve, reject) => {
		post('/Product/GetProductDetail', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 可领优惠券列表
export function getDisList(data) {
	return new Promise((resolve, reject) => {
		post('/Discount/GetDisList', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 领优惠券确定
export function receiveCoupon(data) {
	return new Promise((resolve, reject) => {
		post('/Discount/ReceiveCoupon', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 获取商品评价标签
export function getProductEvTip(data) {
	return new Promise((resolve, reject) => {
		post('/Evaluate/GetProductEvTip', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 获取商品评价列表
export function getProductEvaluate(data) {
	return new Promise((resolve, reject) => {
		post('/Evaluate/GetProductEvaluate', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 已经登录加入购物车
export function addCart(data) {
	return new Promise((resolve, reject) => {
		post('/ShoppingCart/AddCart', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 已经登录加入购物车
export function addCollect(data) {
	return new Promise((resolve, reject) => {
		post('/Collect/AddCollect', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 已经登录加入购物车
export function deleteCollect(data) {
	return new Promise((resolve, reject) => {
		post('/Collect/DeleteCollect', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 推荐商品列表
export function getRecommend(data) {
	return new Promise((resolve, reject) => {
		post('/Product/GetRecommend', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
