<template>
	<div class="product">
		<div class="product-main">
			<div class="breadcrumb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="cursor: pointer;" v-for="(item, index) in productType" :key="index">
						<span @click="handleNav(item, index)">{{ item.value }}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="me-main-center">
				<div class="me-main-left" v-if="list.length > 0 || priceList.length > 0">
					<div>
						<div class="title">价格</div>
						<div :class="['list', { active: j.active }]" v-for="(j, ji) in priceList" :key="ji"
							@click="handleSearchPrice(j, ji)">{{ j.title }}</div>
					</div>
					<div v-for="(k, i) in list" :key="i">
						<div class="title">{{ k.CName }}</div>
						<div :class="['list', { active: j.active }]" v-for="(j, ji) in k.CondValues" :key="ji"
							@click="handleSearch(k, j)">{{ j.title }}</div>
					</div>
				</div>
				<div class="me-main-right">
					<div class="tabs">
						<div>
							<div @click="changeTabs(item, index)" :class="{ active: item.active }" v-for="(item, index) in tabs"
								:key="index">
								<span v-if="!aid">
									{{ item.title }}
									<i v-if="item.price == 2" :class="['iconfont iconsanjiaofuxia_shang']"></i>
									<i v-if="item.price == 1" :class="['iconfont iconinsert-bottom ss']"></i>
									<i v-if="item.price == 0" :class="['iconfont iconinsert-bottom']"></i>
								</span>
							</div>
						</div>
						<div class="tabs-right">
							<i v-for="(item, index) in tabsList" :key="index" @click="changeType(index)"
								:class="['iconfont', item.icon, { active: item.active }]"></i>
						</div>
					</div>
					<div class="cards" style="margin-top:20px;" v-if="tabsList[1].active">
						<el-row :gutter="15">
							<el-col :span="6" v-for="(item, index) in plist" :key="index">
								<div class="commodity-list" @click="viewProject(item)">
									<div class="commodity-card" shadow="never" :body-style="{ padding: '10px' }">
										<el-image style="width: 226px; height: 240px;" :src="pic + item.Picture"
											fit="scale-down"></el-image>
									</div>
									<div class="commodity-text">
										<div class="code" style="height: 30px">{{ item.ProName }}</div>
										<div class="name" style="height: 30px">{{ item.Description }}</div>
										<div class="sku">SKU: {{ item.BarCode }}</div>
										<div class="bottom">
											<div class="bottom-top">
												<div class="price">
													￥{{ item.Price }}
													<span v-if="aid" class="original">￥{{ item.InTaxPrice }}</span>
												</div>
												<div v-if="item.PLUSPrice && item.Price != item.PLUSPrice" class="PLUS">
													￥{{ item.PLUSPrice }}
													<img style="width: 30px" src="../../assets/plus.png" @click="toplus" alt="" />
												</div>
												<div>
													<i class="iconfont icongouwucheman"
														style="margin-top: 6px;margin-left: 5px;color: #1b98aa; cursor: pointer"
														@click.stop="handleAddCart(item)"></i>
												</div>
											</div>
											<div class="bottom-bottom" v-if="!aid">
												<div>
													<span class="original">￥{{ item.InTaxPrice }}</span>
												</div>
												<div class="sold">已售{{ item.VirtualSales }}件</div>
											</div>
										</div>
										<div class="border"></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div v-else>
						<!--列表式-->
						<div class="list-pro" v-for="(item, index) in plist" :key="index">
							<div>
								<div class="list-pro-top" @click="viewProject(item)">
									<div class="list-pro-pic" shadow="never" :body-style="{ padding: '10px' }"><img
											:src="pic + item.Picture" class="image" /></div>
									<div class="list-pro-text">
										<div class="title">{{ item.ProName }}</div>
										<div class="sku">SKU: {{ item.BarCode }}</div>
										<div class="description">{{ item.Description }}</div>
									</div>
									<div class="list-pro-right">
										<div class="price">￥{{ item.Price }}</div>
										<div class="original">￥{{ item.InTaxPrice }}</div>
										<div class="PLUS" v-if="item.PLUSPrice > 0 && item.Price != item.PLUSPrice">
											<div>￥{{ item.PLUSPrice }}</div>
											<div><img src="../../assets/plus.png" @click="toplus" alt="" /></div>
										</div>
										<div class="sold" v-if="!aid">已售{{ item.VirtualSales }}件</div>
									</div>
								</div>

								<div class="list-pro-bottom">
									<div class="wrap" v-for="(j, i) in item.Condition" :key="i">
										<div class="left">{{ j.CName }}</div>
										<div class="right">{{ j.DetailValue }}</div>
									</div>
								</div>
								<!-- <div class="list-pro-bottom">
									<div class="wrap" v-for="(j, i) in item.Condition" :key="i">{{ j.DetailValue }}</div>
								</div> -->
								<div class="number">
									<div>
										购买数量 :
										<el-input-number size="mini" :max="item.Stock" :min="1" v-model="item['num']"
											label="描述文字"></el-input-number>
									</div>
								</div>
								<div class="join">
									<div @click="handleAddCart(item)" style="cursor: pointer">
										<i class="iconfont icongouwucheman"></i>
										加入购物车
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pagination" v-if="count > 0">
						<div>
							<el-pagination background @size-change="changeSize" @current-change="changeCurrent"
								:page-sizes="[12, 20, 50, 100]" :page-size="params.PageSize"
								layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getConditionList,
		getProductList,
		GetPromotionList,
		getPriceCondition,
		getProductType
	} from './service';

	import {
		addCart
	} from '../commodity/service';
	import qs from 'qs';

	export default {
		data() {
			return {
				aid: null,
				pic: FILE_URL,
				num: 1,
				data: 0,
				count: 0,
				priceList: [],
				productType: [],
				params: {
					Sale: 0,
					Reputation: 0,
					Issued: 0,
					condition: '',
					Price: null,
					PageIndex: 1,
					PageSize: 12,
					keyword: '',
					BigType: null,
					MidType: null,
					SmaType: null
				},
				activeName: '',
				tabsList: [{
						icon: 'iconliebiao'
					},
					{
						icon: 'iconwangge',
						active: true
					}
				],
				tabs: [{
						title: '上架时间'
					},
					{
						title: '好评量'
					},
					{
						title: '销量'
					},
					{
						title: '价格',
						price: 2,
						icon: 'iconsanjiaofuxia_shang'
					}
				],
				list: [],
				plist: []
			};
		},
		mounted() {
			this.params.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
			this.init();
		},
		watch: {
			$route(to, from) {
				//监听路由是否变化
				if (to.query.keyword != from.query.keyword) {
					// console.log(to.query.keyword);
					this.params.keyword = to.query.keyword;
					// console.log(this.params);
					this.init(); //重新加载数据'
				} else {
					this.init(); //重新加载数据'
				}
			}
		},
		methods: {
			GetPromotionList() {
				if (!this.priceList || this.priceList.length == 0) {
					this.priceList = [{
							active: false,
							price: 200,
							title: '≤200',
							type: 'Min'
						},
						{
							active: false,
							price: [200, 500],
							title: '≥200 - ≤500',
							type: 'minMax'
						},
						{
							active: false,
							price: 500,
							title: '≥500',
							type: 'Max'
						}
					];
				}
				GetPromotionList(this.$route.query.aid).then(res => {
					this.count = res.count;
					for (const plistKey in res.data) {
						res.data[plistKey].num = 1;
					}
					this.PromotionList = res.data;
					this.plist = res.data;
				});
			},

			changeType(index) {
				for (const tabsKey in this.tabsList) {
					this.tabsList[tabsKey].active = false;
				}
				this.tabsList[index].active = true;
			},
			// 加入购物车
			handleAddCart(s) {
				// 已经登录执行接口
				// let isLogin = localStorage.getItem('userToken');
				if (this.$store.state.isLogin) {
					addCart({
						PID: s.ID,
						Count: s.num
					});
					this.cartVisible = true;
					this.$store.commit('add');
					return;
				} else {
					// localStorage.setItem('u', '');
					this.clearLocalStorage();
				}
				// 未登录添加缓存
				// this.setCartStorage([
				// 	{
				// 		code: arrs[0],
				// 		count: arrs[1]
				// 	}
				// ]);

				this.setCartStorage([{
					code: s.BarCode,
					count: 1
				}]);

				this.$store.commit('add');
				this.cartVisible = true;
				this.$message.success('添加成功');
			},
			viewProject(data) {
				let u = localStorage.getItem('u');
				window.open(`/commodity?pid=${data.ID}&u=${u}`, '_blank').location;
			},
			changeSize(size) {
				this.params.PageSize = size;
				this.initProject();
			},
			changeCurrent(size) {
				this.params.PageIndex = size;
				this.initProject();
			},
			async init() {
				if (!this.$route.query.aid) {
					this.data = this.$route.query;
					let that = this;
					let obj = {};
					switch (this.data.type) {
						case '2':
							obj.BigType = this.$route.query.link;
							this.params.BigType = this.$route.query.link;
							break;
						case '3':
							obj.MidType = this.$route.query.link;
							this.params.MidType = this.$route.query.link;
							break;
						case '4':
							obj.SmaType = this.$route.query.link;
							this.params.SmaType = this.$route.query.link;
							break;
						case '6':
							obj.BigType = this.$route.query.BigType;
							obj.MidType = this.$route.query.MidType;
							obj.SmaType = this.$route.query.SmaType;
							this.params.SmaType = this.$route.query.SmaType;
							this.params.BigType = this.$route.query.BigType;
							this.params.MidType = this.$route.query.MidType;
							break;
					}
					if (this.$route.query.keyword) {
						obj.BigType = '';
						obj.MidType = '';
						obj.SmaType = '';
						this.params.SmaType = '';
						this.params.BigType = '';
						this.params.BigType = '';
					}
					// 根据分类获取筛选条件
					await getProductType(obj).then(res => {
						this.productType = res.data;
					});

					let arr;
					if (this.$route.query.ids) {
						arr = JSON.parse(this.$route.query.ids);
					}
					// 根据分类获取筛选条件
					await getConditionList(obj).then(res => {
						this.list = res.data.List;
						for (const listKey in this.list) {
							let value = this.list[listKey].CondValues.split(',');
							this.list[listKey].CondValues = [];
							for (const valueKey in value) {
								this.list[listKey].CondValues.push({
									title: value[valueKey],
									active: false
								});
							}
							for (let j in this.list[listKey].CondValues) {
								for (let jj in arr) {
									if (arr[jj].CondID == this.list[listKey].ConditionID && arr[jj].CondValue == this.list[listKey]
										.CondValues[j].title) {
										this.list[listKey].CondValues[j].active = true;
									}
								}
							}
						}
						let shuju = '';
						for (const listKey in this.list) {
							for (const condValuesKey in this.list[listKey].CondValues) {
								if (this.list[listKey].CondValues[condValuesKey].active) {
									shuju += this.list[listKey].ConditionID + ',' + this.list[listKey].CondValues[condValuesKey]
										.title + '|';
								}
							}
						}
						shuju = shuju.substr(0, shuju.length - 1);
						this.params.condition = shuju;
					});
					this.priceList = [];
					getPriceCondition(obj).then(res => {
						for (const key in res.data) {
							this.priceList.push({
								title: key == 'Min' ? `≤${res.data[key]}` : `≥ ${res.data[key]}`,
								price: res.data[key],
								type: key,
								active: false
							});
						}
						let data = {
							title: `≥${this.priceList[0].price} - ≤${this.priceList[1].price}`,
							price: [this.priceList[0].price, this.priceList[1].price],
							type: 'minMax',
							active: false
						};
						this.priceList.splice(1, 0, data); //
						this.priceList.push();
					});
				}
				this.initProject();
			},
			initProject() {
				if (this.$route.query.aid && Number(this.$route.query.aid) > 0) {
					this.aid = this.$route.query.aid;
					if (!this.PromotionList || this.PromotionList.length == 0) {
						this.productType = [{
								ID: 0,
								label: '',
								value: '首页'
							},
							{
								ID: 0,
								label: '',
								value: this.$route.query.title
							}
						];
						this.GetPromotionList();
					} else {
						// this.PromotionList = res.data;
						// this.plist = res.data;
						let price = [];
						for (let i = 0; i < this.priceList.length; i++) {
							if (this.priceList[i].active) {
								if (i == 0) {
									price = [0, this.priceList[i].price];
								} else if (i == 1) {
									price = this.priceList[i].price;
								} else {
									price = [this.priceList[i].price];
								}
								break;
							}
						}
						if (price.length == 0) {
							this.plist = this.PromotionList;
						} else if (price.length == 1) {
							this.plist = [];
							this.PromotionList.forEach(v => {
								if (v.Price >= price[0]) {
									this.plist.push(v);
								}
							});
						} else if (price.length == 2) {
							this.plist = [];
							this.PromotionList.forEach(v => {
								if (v.Price >= price[0] && v.Price <= price[1]) {
									this.plist.push(v);
								}
							});
						}
						this.$forceUpdate();
					}
				} else {
					// 获取商品列表
					getProductList(this.params).then(res => {
						this.count = res.count;
						for (const plistKey in res.data) {
							res.data[plistKey].num = 1;
						}
						this.plist = res.data;
					});
					this.params.keyword = '';
				}
			},
			handleSearchPrice(data, i) {
				this.params.PageIndex = 1;
				this.params.PageSize = 12;

				for (const key in this.priceList) {
					if (key != i) {
						this.priceList[key].active = false;
					} else {
						this.priceList[key].active = !this.priceList[key].active;
					}
				}

				this.params.MinPrice = '';
				this.params.MaxPrice = '';
				switch (data.type) {
					case 'Min':
						if (data.active) {
							this.params.MaxPrice = data.price;
						} else {
							this.params.MaxPrice = '';
						}
						break;
					case 'Max':
						if (data.active) {
							this.params.MinPrice = data.price;
						} else {
							this.params.MinPrice = '';
						}
						break;
					case 'minMax':
						if (data.active) {
							this.params.MinPrice = data.price[0];
							this.params.MaxPrice = data.price[1];
						} else {
							this.params.MinPrice = '';
							this.params.MaxPrice = '';
						}

						break;
				}

				this.$forceUpdate();
				this.initProject();
			},
			handleNav(data, index) {
				let url = this.$route.query;

				if (data.value == '首页') {
					window.location.href = `/`;
				} else {
					if (index == 1) {
						url = qs.parse(this.$route.query);
						delete url.MidType;
						delete url.SmaType;
					} else if (index == 2) {
						url = qs.parse(this.$route.query);
						delete url.SmaType;
					}

					// delete url[this.productType[index ].label]
					// console.log(this.productType)
					window.location.href = `/product-list?${qs.stringify(url)}`;
				}
			},
			// 搜索
			handleSearch(prent, data) {
				let shuju = '';
				data.active = !data.active;
				let arr = [];
				this.params.PageIndex = 1;
				this.params.PageSize = 12;
				for (const listKey in this.list) {
					for (const condValuesKey in this.list[listKey].CondValues) {
						if (this.list[listKey].CondValues[condValuesKey].active) {
							arr.push(this.list[listKey].CondValues[condValuesKey].title);
							shuju += this.list[listKey].ConditionID + ',' + this.list[listKey].CondValues[condValuesKey].title + '|';
						}
					}
				}
				this.params.condition = shuju;
				shuju = shuju.substr(0, shuju.length - 1);
				this.params.condition = shuju;
				this.initProject();
			},
			toplus() {
				if (!localStorage.getItem('userToken')) {
					this.toCIAMLoginIn();
					// this.$message.success('正在跳转登录');
					// setTimeout(() => {
					// 	let url = encodeURI(window.location.href);
					// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
					// }, 1000);
				} else {
					window.open(`/me/plus`, '_blank').location;
				}
			},
			// tabs切换
			changeTabs(data, index) {
				for (const tabsKey in this.tabs) {
					if (index != tabsKey) {
						this.tabs[tabsKey].active = false;
					}
				}
				if (data.title == '价格') {
					this.tabs[3].active = 1;
					if (this.tabs[3].price == 2) {
						this.tabs[3].price = 1;
						this.params.Price = 0;
					} else if (this.tabs[3].price == 1) {
						this.tabs[3].price = 0;
						this.params.Price = 1;
					} else if (this.tabs[3].price == 0) {
						this.tabs[3].price = 1;
					}
					this.tabs.splice(0, 0);
					this.initProject();
					this.params.Issued = 0;
					this.params.Reputation = 0;
					this.params.Solt = 0;
					return;
				}
				this.tabs[3].price = 2;
				this.params.Price = null;
				this.tabs[index].active = !this.tabs[index].active;
				this.tabs.splice(0, 0);
				switch (data.title) {
					case '上架时间':
						this.params.Issued = 1;
						this.params.Reputation = 0;
						this.params.Solt = 0;
						break;
					case '好评量':
						this.params.Issued = 0;
						this.params.Reputation = 1;
						this.params.Solt = 0;
						break;
					case '销量':
						this.params.Issued = 0;
						this.params.Reputation = 0;
						this.params.Solt = 1;
						break;
				}
				this.initProject();
			}
		}
	};
</script>

<style lang="less" scoped>
	.list-pro-bottom {
		margin-left: 120px;
		display: flex;
		flex-direction: column;
		width: 700px;

		.wrap {
			flex: 1;
			color: #666666;
			text-align: center;
			padding: 10px 0;
			display: flex;

			>div {
				flex: 1;
			}
		}

		.wrap:nth-child(2n) {
			background-color: #e8f0f3;
		}

		.wrap:nth-child(2n + 1) {
			background-color: #fff;
		}

		.wrap:nth-child(1) {
			margin-top: 20px;
		}
	}

	.list-pro {
		background: #e8f0f3;

		.join {
			div {
				padding: 13px 90px;
			}

			div:hover {
				border: 2px solid #0097ba;
				color: #ffffff;
				background: #0097ba;
			}

			i {
				margin-right: 5px;
			}
		}
	}

	.list-pro:nth-of-type(odd) {
		background: #fff;
	}

	.list-pro-top {
		display: flex;
	}

	.original {
		color: #666666;
		text-decoration: line-through;
		font-size: 13px;
		margin: 5px 0;
	}

	.list-pro-right {
		border-bottom: 1px solid #dedede;
		text-align: right;

		.price {
			color: #1b98aa;
			font-family: 'ct';
			font-size: 18px;
		}

		.PLUS {
			color: #000000;
			font-family: 'ct';
			font-size: 18px;
			display: flex;

			img {
				margin-left: 5px;
			}
		}

		.sold {
			margin-left: 20px;
			margin-top: 15px;
			font-size: 12px;
			color: #666666;
			margin-bottom: 5px;
		}
	}

	.join {
		display: flex;
		justify-content: flex-end;
		margin: 50px 0;

		div {
			border: 2px solid #0097ba;
			padding: 5px 40px;
			color: #0097ba;
		}

		i {
			margin-right: 5px;
		}
	}

	.number {
		display: flex;
		justify-content: flex-end;
		margin-top: 50px;
		font-size: 13px;
	}

	.list-pro-text {
		width: 900px;
		margin-left: 20px;
		border-bottom: 1px solid #dedede;

		.description {
			width: 600px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 13px;
		}

		.title {
			font-family: 'ct';
			white-space: nowrap;
			overflow: hidden;
			color: #0097ba;
			text-overflow: ellipsis;
		}

		.name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
			font-family: 'nl';
			letter-spacing: 0;
			overflow: hidden;
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			/*要显示的行数*/
			-webkit-box-orient: vertical;
			font-size: 12px;
		}

		.sku {
			width: fit-content;
			padding: 2px 10px;
			background: #f5f5f5;
			border: 1px solid #dedede;
			border-radius: 15px;
			color: #333333;
			font-size: 12px;
			margin: 10px 0px;
		}
	}

	.list-pro {
		padding: 30px;
		box-sizing: border-box;
	}

	.list-pro-pic:hover {
		.star {
			display: block;
		}
	}

	.list-pro-pic {
		width: 100px;
		height: 100px;
		padding: 2px;
		border: 1px solid #e5e5e5;
		position: relative;

		img {
			width: 100px;
			height: 100px;
		}

		.star {
			cursor: pointer;
			width: 30px;
			height: 30px;
			line-height: 30px;
			position: absolute;
			top: 5px;
			right: 5px;
			text-align: center;
			background: #eeeeee;
			border-radius: 5px;
			display: none;

			img {
				width: 15px;
				height: 15px;
				margin-top: 7px;
			}
		}
	}

	.ss {
		transform: rotate(180deg);
	}

	.tabs-right {
		i {
			font-size: 14px !important;
			margin-left: 10px !important;
		}
	}

	.active {
		color: #1b98aa;
	}

	.product-main {
		width: 1240px;
		margin: 30px auto;

		.me-main-center {
			display: flex;
		}

		.me-main-right {
			margin-left: 20px;
			width: 800px;
		}

		.me-main-left {
			background: #ffffff;
			border: 1px solid #dcdcdc;
			padding: 20px 10px;
			box-sizing: border-box;

			.title {
				font-family: 'ct';
				margin: 10px 20px;
			}

			.list {
				color: #666666;
				cursor: pointer;
				margin: 10px 20px;
				padding: 5px 25px;
				background: #f9f9f9;
			}

			.active {
				background: rgba(27, 152, 170, 0.2);
				color: #1b98aa;
				padding: 5px 25px;
			}
		}

		.me-main-right {
			flex: 1;
		}

		.tabs {
			display: flex;
			background: #f5f5f5;
			padding: 10px 20px;
			justify-content: space-between;

			div {
				margin-right: 20px;
				font-size: 12px;
				cursor: pointer;
				display: flex;
			}

			i {
				margin-top: 2px;
				margin-left: 2px;
				font-size: 9px;
			}
		}
	}

	.commodity-card {
		cursor: pointer;
		width: 100%;
		height: 250px;
		border: 1px solid #e5e5e5;
		padding: 5px;
		box-sizing: border-box;
		text-align: center;
		line-height: 271px;

		img {
			max-width: 230px;
			max-height: 230px;
		}
	}

	.breadcrumb {
		margin: 20px 0;
	}

	.sign {
		color: #0097ba;
	}

	.commodity-text {
		margin-top: 20px;

		.code {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
		}

		.name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
			font-family: 'nl';
		}

		.sku {
			margin: 8px 0;
			font-size: 12px;
			color: #333333;
		}

		.bottom {
			.bottom-top {
				display: flex;
				justify-content: space-between;

				.price {
					color: #1b98aa;
					font-family: 'ct';
					font-size: 18px;
				}

				.PLUS {
					color: #000000;
					font-family: 'ct';
					font-size: 18px;
					margin-left: 10px;
				}
			}

			.bottom-bottom {
				display: flex;
				justify-content: space-between;

				.original {
					color: #666666;
					text-decoration: line-through;
				}

				.sold {
					color: #666666;
				}
			}
		}

		.border {
			height: 2px;
			margin-top: 10px;
			background: #bbbfbe;
		}
	}

	.commodity-list {
		position: relative;
		height: 440px;

		.star {
			width: 30px;
			height: 30px;
			line-height: 30px;
			position: absolute;
			top: 10px;
			right: 0px;
			text-align: center;
			background: #eeeeee;
			border-radius: 5px;
			display: none;

			img {
				width: 15px;
				height: 15px;
				margin-top: 7px;
			}
		}
	}

	.commodity-list:hover {
		.border {
			height: 2px;
			background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
		}

		.star {
			display: block;
		}
	}

	.pagination {
		display: flex;
		margin-top: 30px;
		justify-content: flex-end;
	}
</style>
<style scoped>
	>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #1b98aa;
	}
</style>